import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { IApiBlockchainName } from 'api/types';
import {
  IEthTxDetailsMap,
  IMoneyTransfer,
  INftTransfer,
  ITransactionFormatted,
} from 'common/types';
import { t } from 'common/utils/intl';
import { DetailsSkeleton } from 'components/DetailsSkeleton';
import { ErrorMessage } from 'components/ErrorMessage';
import { Layout } from 'modules/Layout';
import { Container } from 'uiKit/Container';
import { PageTop } from '../../../../components/PageTop';
import { EthDetails } from '../EthDetails';
import { TxTransfers } from '../TxTransfers/TxTransfers';

interface ITransactionUIProps {
  blockchain?: IApiBlockchainName;
  ethTx?: IEthTxDetailsMap;
  commonTxData?: ITransactionFormatted;
  isLoading: boolean;
  error?: Error;
  moneyTransfers?: IMoneyTransfer[];
  nftTransfers?: INftTransfer[];
  refetch: () => void;
}

export const TransactionUI = ({
  blockchain,
  ethTx,
  commonTxData,
  isLoading,
  error,
  moneyTransfers,
  nftTransfers,
  refetch,
}: ITransactionUIProps) => {
  const renderedError = () => {
    return (
      <>
        <ErrorMessage
          title={error?.name}
          text={error?.message}
          onClick={refetch}
        >
          <Typography>
            <span title="FYI">{'⚡'}</span>{' '}
            {t('errorMessages.transactionNotFound')}
          </Typography>
        </ErrorMessage>
      </>
    );
  };

  return (
    <Layout>
      <Box component="section" pt={{ xs: 4, md: 7.5 }} pb={7}>
        <Container>
          <PageTop>
            <Typography variant="h2">{t('transaction.title')}</Typography>
          </PageTop>

          {error && renderedError()}

          {isLoading && <DetailsSkeleton />}

          {ethTx && blockchain && commonTxData && (
            <EthDetails
              data={ethTx}
              blockchain={blockchain}
              common={commonTxData}
            />
          )}

          <TxTransfers
            moneyTransfers={moneyTransfers}
            nftTransfers={nftTransfers}
          />
        </Container>
      </Box>
    </Layout>
  );
};
