import { INft } from 'common/types';
import { customException } from 'common/utils/customException';
import { IS_EMBEDDED, CUSTOM_CHAINS } from '../common/const';
import { explorerRpc } from './explorerRpc';
import { IApiBlockchainName, IApiNft } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IRequestParams {
  address: string;
  nextPageToken?: string;
  blockchain?: IApiBlockchainName;
}

interface IAddressNftsQuery {
  items?: INft[];
  nextPageToken?: string;
}

interface IAddressNftsReply {
  result?: {
    nfts?: IApiNft[];
    nextPageToken?: string;
  };
  error?: {
    code: number;
    message: string;
  };
}

export const { useGetAddressNftsQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getAddressNfts: build.query<IAddressNftsQuery, IRequestParams>({
      query: ({ address, nextPageToken, blockchain }) =>
        buildRpcRequest({
          method: 'explorer_getAddressNFTs',
          params: {
            address,
            pageToken: nextPageToken,
            blockchain: blockchain || (IS_EMBEDDED ? CUSTOM_CHAINS : undefined),
          },
        }),

      transformResponse: (data: IAddressNftsReply) => {
        if (data.error) {
          throw customException(data.error);
        }

        return {
          items: data.result?.nfts?.map(nft => {
            return {
              ...nft,
              blockchain: nft.blockchain,
              tokenType: nft.contractType.toString(),
              image: nft.imageUrl,
              balance: nft.quantity ? +nft.quantity : 1,
              blockchainLogo: '',
            };
          }),
          nextPageToken: data?.result?.nextPageToken,
        };
      },
    }),
  }),
  overrideExisting: false,
});
