import { makeStyles } from '@material-ui/core';
import { PALETTE } from '../../common/themes/mainTheme';

export const useAddressStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(5, 0, 7),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7.5, 0),
    },
  },
  header: {
    display: 'grid',
    gridTemplateColumns: 'auto 1fr',
    alignItems: 'end',
    gap: theme.spacing(0, 1),
  },
  title: {
    fontWeight: 700,
    color: theme.palette.primary.main,
  },
  addressText: {
    position: 'relative',
    bottom: 1,
    fontSize: 14,
    lineHeight: '25px',
    maxWidth: '100%',
    color: theme.palette.primary.light,
  },
  balance: {
    marginBottom: theme.spacing(4),
  },
  nfts: {
    marginBottom: theme.spacing(4),
  },
  subTitle: {
    marginBottom: theme.spacing(1),

    [theme.breakpoints.up('md')]: {
      marginBottom: theme.spacing(3),
    },
  },
}));
