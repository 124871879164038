import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { IBlocksQuery } from 'api/getLatestBlocks';
import { t } from 'common/utils/intl';
import { Search } from 'components/Search';
import { Container } from 'uiKit/Container';
import { useLatestBlocks } from '../Latest/useLatestBlocks';
import { useFilterStyles } from './FilterStyles';

function Header(data: IBlocksQuery | undefined) {
  return (
    <Box textAlign="center" mb={5}>
      <Typography variant="h1">{t('mainPage.title')}</Typography>
      <Typography variant="body1">
        {t('mainPage.text', {
          number: data?.items ? data.items.length : '',
        })}
      </Typography>
    </Box>
  );
}

interface IFilterProps {
  HeaderComponent?: (data: IBlocksQuery | undefined) => JSX.Element;
}

export const Filter = ({ HeaderComponent = Header }: IFilterProps) => {
  const classes = useFilterStyles();
  const { data } = useLatestBlocks();

  return (
    <Box className={classes.root} component="section">
      <HeaderComponent {...data} />
      <Search className={classes.searchBox} />
    </Box>
  );
};
