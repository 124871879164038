import React, { useMemo } from 'react';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import { nftsCaptions } from 'common/const';
import { useBlockchainName } from 'common/hooks/useBlockchainName';
import { t } from 'common/utils/intl';
import { IBlockchainForAddressInfo } from 'components/Blockchains/types';
import { ErrorMessage } from 'components/ErrorMessage';
import { NothingFoundTxs } from 'components/NothingFound';
import {
  Table,
  TableBody,
  TableBodyCell,
  TableCellHash,
  TableHead,
  TableHeadCell,
  TableRow,
} from 'components/TableComponents';
import { TableShowOlder } from 'components/TableShowMore';
import { AddressRoutesConfig, ChainRoutesConfig } from 'modules/router/const';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { TransactionsTableRowSkeleton } from 'modules/Transactions/components/TransactionsUI/TransactionsTableRow/TransactionsTableRowSkeleton';
import { ImageWithExternalSrc } from 'uiKit/ImageWithExternalSrc/ImageWithExternalSrc';
import { useNftsTable } from './useNftsTable';

export const NftsTable = ({
  blockchain,
}: {
  blockchain?: IBlockchainForAddressInfo;
}) => {
  const classes = useTransactionsTableCellStyles();
  const {
    error,
    loadData,
    visibleItems,
    loading,
    isFetching,
    loadNext,
    hasNextPage,
  } = useNftsTable(blockchain);

  const renderedError = error && (
    <ErrorMessage
      noBorderRadius
      text={t('errorMessages.requestError')}
      onClick={loadData}
    />
  );

  const renderedTablePagination = (
    <TableShowOlder
      onLoadOlder={loadNext}
      hasNextPage={hasNextPage}
      isLoading={isFetching}
    />
  );

  const renderedRows = useMemo(
    () =>
      visibleItems?.map(
        (
          {
            tokenId,
            image,
            name,
            collectionName,
            contractAddress,
            blockchain,
            blockchainLogo,
            description,
            balance,
          },
          i,
        ) => {
          const chainName = useBlockchainName(blockchain);

          return (
            <TableRow key={uid(contractAddress + name, i)}>
              <TableBodyCell
                className={classes.tableCell}
                label={nftsCaptions[0].label}
                tooltipText={name}
              >
                <div className={classes.hashCell}>
                  <ImageWithExternalSrc
                    className={classes.iconNft}
                    alt="icon"
                    key={image}
                    src={image}
                  />

                  <div className={classes.ellipsis}>{name || '—'}</div>
                </div>
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={nftsCaptions[1].label}
              >
                {tokenId}
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={nftsCaptions[2].label}
              >
                <Link
                  to={AddressRoutesConfig.address.generatePath(contractAddress)}
                  title={collectionName || contractAddress}
                >
                  {collectionName || contractAddress}
                </Link>
              </TableBodyCell>

              <TableCellHash
                label={nftsCaptions[3].label}
                blockchain={blockchain}
                blockchainLogo={blockchainLogo}
                customTitle={
                  <Link
                    to={ChainRoutesConfig.chainDetails.generatePath(blockchain)}
                  >{`${chainName} (${blockchain})`}</Link>
                }
              />

              <TableBodyCell
                className={classes.tableCell}
                label={nftsCaptions[4].label}
                tooltipText={description}
              >
                <div className={classes.ellipsis}>{description || '—'}</div>
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={nftsCaptions[5].label}
              >
                {balance}
              </TableBodyCell>
            </TableRow>
          );
        },
      ),
    [classes, visibleItems],
  );

  const renderTableBody = () => {
    if (visibleItems?.length) {
      return renderedRows;
    } else if (loading) {
      return <TransactionsTableRowSkeleton captions={nftsCaptions} />;
    }
  };

  return (
    <>
      {renderedError}
      {(visibleItems?.length || loading) && (
        <>
          <Table
            customCell="2fr 1fr 1.5fr 1.8fr 2.5fr 85px"
            columnsCount={nftsCaptions.length}
            className={classes.table}
            classes={{
              table: classes.tableInner,
            }}
            withFooter
          >
            <TableHead className={classes.head}>
              {nftsCaptions.map(cell => (
                <TableHeadCell
                  className={classes.headCell}
                  key={cell.key}
                  label={cell.label}
                />
              ))}
            </TableHead>
            <TableBody>{renderTableBody()}</TableBody>
          </Table>
          {renderedTablePagination}
        </>
      )}
      {!visibleItems?.length && !loading && <NothingFoundTxs />}
    </>
  );
};
