import React, { ReactElement, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { IApiBlockchainName } from 'api/types';
import { TransactionsRouteConfig } from 'modules/router/const';
import { useTransactionsTableCellStyles } from 'modules/Transactions/components/TransactionsUI/TransactionsTableCellStyles';
import { BlockchainIcon } from '../../BlockchainIcon';
import { TableBodyCell } from '../TableBodyCell';
import { TxTitle } from './TxTitle';

interface IProps {
  label: string;
  blockchain: IApiBlockchainName;
  blockchainLogo: string;
  transactionHash?: string;
  success?: boolean;
  customTitle?: string | ReactElement;
}

export const TableCellHash = ({
  label,
  blockchain,
  blockchainLogo,
  transactionHash,
  success,
  customTitle,
}: IProps) => {
  const classes = useTransactionsTableCellStyles();
  const {
    transactionDetails: { generatePath },
  } = TransactionsRouteConfig;

  const renderTitle = useMemo(() => {
    if (customTitle) {
      return customTitle;
    }

    if (!transactionHash) {
      return;
    }

    if (typeof success === 'boolean') {
      return (
        <TxTitle
          blockchain={blockchain}
          transactionHash={transactionHash}
          success={success}
        />
      );
    }

    return (
      <Link to={generatePath(blockchain, transactionHash)}>
        {transactionHash}
      </Link>
    );
  }, [generatePath, customTitle, blockchain, transactionHash, success]);

  return (
    <TableBodyCell
      className={classes.tableCell}
      label={label}
      tooltipText={transactionHash}
    >
      <div className={classes.hashCell}>
        <BlockchainIcon
          className={classes.icon}
          type={blockchain}
          title={blockchain}
          logo={blockchainLogo}
        />
        <span className={classes.hashWrap}>{renderTitle}</span>
      </div>
    </TableBodyCell>
  );
};
