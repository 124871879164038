import React, { ReactNode } from 'react';
import { Box, Typography } from '@material-ui/core';
import Skeleton from '@material-ui/lab/Skeleton';
import { EthBlockDetailsMap } from 'common/types';
import { t } from 'common/utils/intl';
import { Layout } from 'modules/Layout';
import { Container } from 'uiKit/Container';
import { DetailsSkeleton } from '../../../../components/DetailsSkeleton';
import { PageTop } from '../../../../components/PageTop';
import { EthDetails } from '../EthDetails';
import { useBlockStyles } from './BlockStyles';

interface IBlockUIProps {
  blockchain?: any;
  ethBlock?: EthBlockDetailsMap;
  isLoading: boolean;
  error: ReactNode;
}

export const BlockUI = ({
  isLoading,
  error,
  blockchain,
  ethBlock,
}: IBlockUIProps) => {
  const classes = useBlockStyles();

  const blockHeight = ethBlock?.blockHeight;

  const renderedTitleHash = isLoading ? (
    <Box display="inline-block" className={classes.titleHash}>
      <Skeleton variant="text" height={42} width={95} />
    </Box>
  ) : (
    <Typography
      variant="h2"
      component="span"
      color="textSecondary"
      className={classes.titleHash}
    >
      {`#${blockHeight}`}
    </Typography>
  );

  return (
    <Layout>
      <Box component="section" pt={{ xs: 4, md: 7.5 }} pb={7}>
        <Container>
          <PageTop>
            <Typography variant="h2" className={classes.title}>
              <Box display="flex" alignItems="center">
                {t('blockUI.title')}
                {error ? null : renderedTitleHash}
              </Box>
            </Typography>
          </PageTop>

          {error}

          {isLoading && <DetailsSkeleton />}

          {ethBlock && blockchain && (
            <EthDetails blockchain={blockchain} {...ethBlock} />
          )}
        </Container>
      </Box>
    </Layout>
  );
};
