import React from 'react';
import process from 'process';
import { IS_SINGLE_CHAIN } from '../../common/const';
import { featureConfig } from '../../common/featureConfig';
import { Layout } from '../Layout';
import { Filter } from './components/Filter';
import { Latest } from './components/Latest';
import { NeuraFilterHeader } from './components/NeuraFilterHeader/NeuraFilterHeader';

export const Lobby = () => {
  return (
    <Layout>
      <Filter
        HeaderComponent={IS_SINGLE_CHAIN ? NeuraFilterHeader : undefined}
      />
      <Latest />
    </Layout>
  );
};
