export type TransferCaptionType = { key: string; label: string };

export const transactionsCaptions: TransferCaptionType[] = [
  {
    key: 'txnHash',
    label: 'Txn Hash',
  },
  {
    key: 'methodName',
    label: 'Method',
  },
  {
    key: 'block',
    label: 'Block',
  },
  {
    key: 'lastSeen',
    label: 'Last Seen',
  },
  {
    key: 'from',
    label: 'From',
  },
  {
    key: 'to',
    label: 'To',
  },
  {
    key: 'val',
    label: 'Value',
  },
];

export const addressTransactionsCaptions: TransferCaptionType[] = [
  {
    key: 'txnHash',
    label: 'Txn Hash',
  },
  {
    key: 'methodName',
    label: 'Method',
  },
  {
    key: 'block',
    label: 'Block',
  },
  {
    key: 'lastSeen',
    label: 'Last Seen',
  },
  {
    key: 'from',
    label: 'From',
  },
  {
    key: 'dir',
    label: '',
  },
  {
    key: 'to',
    label: 'To',
  },
  {
    key: 'val',
    label: 'Value',
  },
];

export const moneyTransfersCaptions: TransferCaptionType[] = [
  {
    key: 'txnHash',
    label: 'Txn Hash',
  },
  {
    key: 'lastSeen',
    label: 'Last Seen',
  },
  {
    key: 'from',
    label: 'From',
  },
  {
    key: 'dir',
    label: '',
  },
  {
    key: 'to',
    label: 'To',
  },
  {
    key: 'val',
    label: 'Value',
  },
  {
    key: 'token',
    label: 'Token',
  },
];

export const nftsCaptions: TransferCaptionType[] = [
  {
    key: 'name',
    label: 'NFT Name',
  },
  {
    key: 'tokenId',
    label: 'Token id',
  },
  {
    key: 'collectionName',
    label: 'Collection',
  },
  {
    key: 'blockchain',
    label: 'Chain',
  },
  {
    key: 'description',
    label: 'Description',
  },
  {
    key: 'balance',
    label: 'Balance',
  },
];

export const nftTransactionsCaptions: TransferCaptionType[] = [
  {
    key: 'txnHash',
    label: 'Txn Hash',
  },
  {
    key: 'lastSeen',
    label: 'Last Seen',
  },
  {
    key: 'from',
    label: 'From',
  },
  {
    key: 'dir',
    label: '',
  },
  {
    key: 'to',
    label: 'To',
  },
  {
    key: 'val',
    label: 'Value',
  },
  {
    key: 'tokenId',
    label: 'Token id',
  },
  {
    key: 'collectionName',
    label: 'Collection Name',
  },
  {
    key: 'token',
    label: 'Token',
  },
];
