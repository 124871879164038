import { ReactNode } from 'react';
import { APP_BRAND, IS_EMBEDDED } from 'common/const';
import { featureConfig } from 'common/featureConfig';
import { useInitEffect } from 'common/hooks/useInitEffect';
import { PageLayout } from 'modules/Layout/components/PageLayout';
import { Footer } from 'modules/Layout/screens/Layout/components/Footer';
import { Header } from 'modules/Layout/screens/Layout/components/Header';
import { NeuraFooter } from './components/NeuraFooter';
import { NeuraHeader } from './components/NeuraHeader';
import { useFooter } from './hooks/useFooter';

interface ILayoutProps {
  children: ReactNode;
}

export const Layout = ({ children }: ILayoutProps) => {
  useInitEffect(() => {
    window.scrollTo(0, 0);
  });

  const footer = useFooter();

  if (APP_BRAND) {
    return (
      <PageLayout headerSlot={<NeuraHeader />} footerSlot={footer}>
        {children}
      </PageLayout>
    );
  }

  if (IS_EMBEDDED) {
    return <>{children}</>;
  }

  return (
    <PageLayout headerSlot={<Header />} footerSlot={<Footer />}>
      {children}
    </PageLayout>
  );
};
