import { makeStyles, Theme } from '@material-ui/core';

export const useBlocksStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(5, 0),

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(7.5, 0),
    },
  },
  title: {
    fontWeight: 700,
    '&&': {
      color: theme.palette.primary.main,
    },
  },
  headCell: {
    '&:first-child': {
      paddingLeft: 40,
    },
  },
  table: {
    paddingTop: 20,
    backgroundColor: '#ffffff',
    borderRadius: '20px',
    boxSizing: 'border-box',
    border: '1px solid #E6E6E6',
    overflow: 'hidden',
  },
  tableBody: {
    [theme.breakpoints.up('md')]: {
      padding: 0,
    },
  },
  blockCell: {
    display: 'grid',
    alignItems: 'center',
    gridTemplateAreas: `'value icon'`,
    gridTemplateColumns: '1fr auto',
    gap: theme.spacing(0, 1.5),
    [theme.breakpoints.up('md')]: {
      gridTemplateAreas: `'icon value'`,
      gridTemplateColumns: 'auto 1fr',
    },
  },
  blockWrap: {
    gridArea: 'value',

    overflow: 'hidden',
    textOverflow: 'ellipsis',
  },
  icon: {
    gridArea: 'icon',
    width: '1em',
    height: '1em',
    fontSize: 28,
  },
}));
