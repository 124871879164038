import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { featureConfig } from 'common/featureConfig';
import { IBlockMapped } from 'common/types';
import { getTimePassed } from 'common/utils/getTimePassed';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  BlockRoutesConfig,
  ChainRoutesConfig,
  TransactionsRouteConfig,
} from 'modules/router/const';
import * as process from 'process';
import { IS_SINGLE_CHAIN } from '../../../../common/const';
import { LatestItemSkeletons } from './LatestItemSkeletons';
import { useLatestBlocks } from './useLatestBlocks';
import { useLatestStyles } from './LatestStyles';

export const LatestBlocks = () => {
  const classes = useLatestStyles();
  const { data, loading, error } = useLatestBlocks();

  const renderBlock = (
    {
      blockchain,
      blockchainLogo,
      blockHeight,
      timestamp,
      transactionsCount,
    }: IBlockMapped,
    index: number,
  ) => {
    const blockLink = BlockRoutesConfig.blockDetails.generatePath(
      blockchain,
      blockHeight,
    );

    const chainLink = ChainRoutesConfig.chainDetails.generatePath(blockchain);

    return (
      <Link
        to={IS_SINGLE_CHAIN ? blockLink : chainLink}
        className={classNames(classes.item, classes.itemBlock)}
        key={uid(blockchain, index)}
      >
        <BlockchainIcon
          className={classes.icon}
          type={blockchain}
          logo={blockchainLogo}
        />

        <Link to={blockLink} className={classes.blockHeight}>
          {blockHeight}
        </Link>

        <Typography className={classes.timePassed}>
          {getTimePassed(timestamp)}
        </Typography>

        {transactionsCount === 0 ? (
          <span className={classes.txns}>{t('latestBlocks.noTxns')}</span>
        ) : (
          <Link
            to={TransactionsRouteConfig.transactionsByBlock.generatePath(
              blockchain,
              blockHeight,
            )}
            className={classes.txns}
          >
            {transactionsCount} {t('latestBlocks.txns')}
          </Link>
        )}
      </Link>
    );
  };

  if (error) {
    return <ErrorMessage noBorders text={t('errorMessages.requestError')} />;
  }

  if (loading) {
    return <LatestItemSkeletons />;
  }

  return <ul className={classes.list}>{data?.items?.map(renderBlock)}</ul>;
};
