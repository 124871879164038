import {
  IS_BROWSER_HISTORY_DISABLED,
  IS_EMBEDDED,
  IS_SCROLL_DISABLED,
  IS_SINGLE_CHAIN,
  SHOW_NFT_TRANSACTIONS,
  SHOW_NFTS,
} from './const';

export const featureConfig = {
  showNFT: SHOW_NFTS,
  /**
   * Disable scroll on the page in case of the widget mode
   */
  disableScroll: IS_SCROLL_DISABLED,
  isContractMethodsActive:
    process.env.REACT_APP_IS_CONTRACT_METHOD_ACTIVE === 'true',
  showNftTransactions: SHOW_NFT_TRANSACTIONS,
  isBrowserHistoryDisabled: IS_BROWSER_HISTORY_DISABLED,
  isSearchInsidePageDisplayed: IS_EMBEDDED && !IS_SINGLE_CHAIN,
} as const;
