import { IBlockMapped } from 'common/types';
import { SUPPORTED_CHAINS } from '../common/const';
import { explorerRpc } from './explorerRpc';
import { IApiBlock, IApiBlockchainName } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IRequestParams {
  blockchainNames?: IApiBlockchainName[] | IApiBlockchainName;
  size?: number;
}

type GetLatestBlocksReply = {
  blocks: IApiBlock[];
};

export interface IBlocksQuery {
  items?: IBlockMapped[];
}

interface IBlockListReply {
  result?: GetLatestBlocksReply;
  error?: {
    code: number;
    message: string;
  };
}

export const { useGetLatestBlocksQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getLatestBlocks: build.query<IBlocksQuery, IRequestParams>({
      query: ({ blockchainNames, size }) =>
        buildRpcRequest({
          method: 'explorer_getLatestBlocks',
          params: {
            blockchain: blockchainNames,
            size,
          },
        }),

      transformResponse: (data: IBlockListReply) => {
        if (data.error) {
          throw data.error;
        }

        return {
          items: data.result?.blocks.map(block => {
            const isBlockchainSupported = SUPPORTED_CHAINS.includes(
              block?.blockchain,
            );
            return {
              ...block,
              ...block.details?.ethBlock,
              blockchainLogo: isBlockchainSupported ? '' : block.blockchainLogo, // used local icons for blockchains
            };
          }),
        };
      },
    }),
  }),
  overrideExisting: false,
});
