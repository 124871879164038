import { useEffect, useState } from 'react';
import { useGetAddressAssetsQuery } from 'api/getAddressAssets';
import { IBalanceMap } from 'common/types';
import { IBlockchainForAddressInfo } from 'components/Blockchains/types';
import { AddressRoutesConfig } from 'modules/router/const';

export const useBalance = (blockchain?: IBlockchainForAddressInfo) => {
  const { address } = AddressRoutesConfig.address.useParams();
  const { data, isError, refetch, isLoading, isFetching } =
    useGetAddressAssetsQuery({ address });

  const [filteredAssets, setFilteredAssets] = useState<IBalanceMap[]>([]);

  const filterBlockchains = () => {
    if (blockchain) {
      setFilteredAssets(
        data?.assets?.filter(asset => asset.blockchain === blockchain) || [],
      );
    } else {
      setFilteredAssets(data?.assets || []);
    }
  };

  useEffect(() => {
    filterBlockchains();
  }, [blockchain, isLoading, isFetching, JSON.stringify(data)]);

  return {
    data: filteredAssets,
    loading: isLoading || isFetching,
    error: isError
      ? {
          name: `error`,
          message: `balance fetch error`,
        }
      : undefined,
    getBalances: refetch,
    totalBalanceUsd: data?.totalBalanceUsd,
  };
};
