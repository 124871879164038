import React from 'react';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import { Typography } from '@material-ui/core';
import classNames from 'classnames';
import { ITransactionFormatted } from 'common/types';
import { getTimePassed } from 'common/utils/getTimePassed';
import { t } from 'common/utils/intl';
import { BlockchainIcon } from 'components/BlockchainIcon';
import { ErrorMessage } from 'components/ErrorMessage';
import {
  AddressRoutesConfig,
  ChainRoutesConfig,
  TransactionsRouteConfig,
} from '../../../router/const';
import { LatestItemSkeletons } from './LatestItemSkeletons';
import { useLatestTransactions } from './useLatestTransactions';
import { useLatestStyles } from './LatestStyles';

export const LatestTransactions = () => {
  const classes = useLatestStyles();
  const { data, loading, error } = useLatestTransactions();

  if (error) {
    return <ErrorMessage noBorders text={t('errorMessages.requestError')} />;
  }

  if (loading) {
    return <LatestItemSkeletons />;
  }

  const { address } = AddressRoutesConfig;
  const { transactionDetails } = TransactionsRouteConfig;

  const renderTransaction = (
    {
      blockchain,
      blockchainLogo,
      transactionHash,
      timestamp,
      fromAddress,
      toAddress,
      valueFormatted,
      valueSymbol,
    }: ITransactionFormatted,
    i: number,
  ) => (
    <Link
      to={ChainRoutesConfig.chainDetails.generatePath(blockchain)}
      className={classNames(classes.item, classes.itemTransaction)}
      key={uid(blockchain, i)}
    >
      <BlockchainIcon
        className={classNames(classes.icon, classes.iconTransaction)}
        type={blockchain}
        logo={blockchainLogo}
      />
      <Link
        to={transactionDetails.generatePath(blockchain, transactionHash)}
        className={classNames(
          classes.blockHeight,
          classes.truncatedStringWrapper,
        )}
      >
        <span className={classes.truncatedString}>{transactionHash}</span>
      </Link>
      <Typography className={classes.timePassed}>
        {getTimePassed(timestamp)}
      </Typography>
      <div className={classNames(classes.miner, classes.minerFrom)}>
        <span className={classes.minerLeft}>
          {t('latestTransactions.from')}
        </span>
        <Link
          to={address.generatePath(fromAddress)}
          className={classes.truncatedStringWrapper}
        >
          <span className={classes.truncatedString}>{fromAddress}</span>
        </Link>
      </div>
      <div className={classNames(classes.miner, classes.minerTo)}>
        <span className={classes.minerLeft}>{t('latestTransactions.to')}</span>
        <Link
          to={address.generatePath(toAddress)}
          className={classes.truncatedStringWrapper}
          title={toAddress}
        >
          <span className={classes.truncatedString}>{toAddress}</span>
        </Link>
      </div>
      <Typography className={classes.amount}>
        <span className={classes.amountNumber}>{valueFormatted}</span>
        {valueSymbol}
      </Typography>
    </Link>
  );

  return (
    <ul className={classes.list}>{data?.items?.map(renderTransaction)}</ul>
  );
};
