import { makeStyles } from '@material-ui/core';

export const useNeuraHeaderStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0),
  },

  container: {
    display: 'flex',
    justifyContent: 'space-between',
    gap: theme.spacing(3),

    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },

  logo: {
    flexShrink: 0,
    padding: 0,
    minHeight: 0,
    height: 54,

    '&:hover': {
      background: 'none',
    },
  },

  logoImg: {
    height: 28,
    width: 177,
  },

  search: {
    maxWidth: 700,
  },
}));
