import React from 'react';
import { useGetBlockTransactionsQuery } from 'api/getBlockTransactions';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { TransactionsRouteConfig } from '../router/const';
import { TransactionsPage } from './components/TransactionsUI/TransactionsPage';

export const TransactionsByBlock = () => {
  const { blockHeight, blockchain } =
    TransactionsRouteConfig.transactionsByBlock.useParams();

  const response = useGetBlockTransactionsQuery({
    blockNumber: blockHeight,
    blockchain,
  });

  const { data, error, refetch, isFetching, isLoading } = response;

  const renderedError = () => {
    return (
      <ErrorMessage
        text={t('errorMessages.requestError')}
        onClick={refetch}
        style={{ marginBottom: 20 }}
      />
    );
  };

  return (
    <TransactionsPage
      data={data?.items}
      isLoading={isLoading || isFetching}
      error={error && renderedError()}
      blockHeight={blockHeight}
      blockchain={blockchain}
    />
  );
};
