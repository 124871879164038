import { makeStyles } from '@material-ui/core';

export const usePageLayoutStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    minHeight: '100vh',
    minWidth: 360,
    fontFamily: theme.typography.fontFamily,
  },

  main: {
    flexGrow: 1,
    display: 'flex',
    flexDirection: 'column',
  },
}));
