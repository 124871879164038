import { APP_BRAND } from '../../../../../common/const';
import { IncentivFooter } from '../components/IncentivFooter';
import { NeuraFooter } from '../components/NeuraFooter';

export function useFooter() {
  switch (APP_BRAND) {
    case 'neura':
      return <NeuraFooter />;
    case 'incentiv':
      return <IncentivFooter />;
    default:
      return null;
  }
}
