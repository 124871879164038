import { useGetTransactionTransfersQuery } from 'api/getTransactionTransfers';
import { t } from 'common/utils/intl';
import { TransactionsRouteConfig } from 'modules/router/const';
import { useWidgetRouteChange } from '../widget';
import { TransactionUI } from './components/TransactionUI/TransactionUI';

export const Transaction = () => {
  const { blockchain, transactionHash } =
    TransactionsRouteConfig.transactionDetails.useParams();

  const { data, isError, isLoading, isFetching, refetch } =
    useGetTransactionTransfersQuery({
      blockchain,
      transactionHash,
    });

  useWidgetRouteChange('tx', transactionHash, blockchain);

  return (
    <TransactionUI
      blockchain={data?.blockchain}
      ethTx={data?.ethTransaction}
      commonTxData={data?.common}
      isLoading={isLoading || isFetching}
      error={
        isError
          ? {
              name: t('errorMessages.error'),
              message: t('errorMessages.requestError'),
            }
          : undefined
      }
      moneyTransfers={data?.moneyTransfers}
      nftTransfers={data?.nftTransfers}
      refetch={refetch}
    />
  );
};
