import { IApiBlockchainName } from 'api/types';

export const IS_SCROLL_DISABLED =
  process.env.REACT_APP_IS_SCROLL_DISABLED === 'true';

export const IS_BROWSER_HISTORY_DISABLED =
  process.env.REACT_APP_IS_BROWSER_HISTORY_DISABLED === 'true';

export const CUSTOM_CHAINS = process.env.REACT_APP_CUSTOM_CHAINS
  ? (JSON.parse(process.env.REACT_APP_CUSTOM_CHAINS) as IApiBlockchainName[])
  : undefined;

export const IS_EMBEDDED = !!CUSTOM_CHAINS;

export const SHOW_NFTS = process.env.REACT_APP_SHOW_NFTS === 'true';

export const SHOW_NFT_TRANSACTIONS =
  process.env.REACT_APP_SHOW_NFT_TRANSACTIONS === 'true';

export const ROOT_PATH = process.env.REACT_APP_PUBLIC_URL ?? '/';

export const APP_BRAND = process.env.REACT_APP_BRAND ?? '';
export const IS_SINGLE_CHAIN = process.env.REACT_APP_IS_SINGLE_CHAIN === 'true';

console.log(
  'IS_SINGLE_CHAIN',
  IS_SINGLE_CHAIN,
  process.env.REACT_IS_SINGLE_CHAIN,
  process.env,
);

export const SOURCIFY_API_URL = process.env.REACT_APP_SOURCIFY_API_URL ?? '';
