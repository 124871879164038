import { IApiBlockchainName, OChainId, TChainId } from 'api/types';

const chainNameToIdMap: Record<IApiBlockchainName, TChainId> = {
  arbitrum: OChainId.arbitrum,
  avalanche: OChainId.avalanche,
  avalanche_fuji: OChainId.avalancheTestnet,
  base: OChainId.base,
  bsc: OChainId.smartchain,
  eth: OChainId.ethereum,
  eth_goerli: OChainId.goerli,
  fantom: OChainId.fantom,
  flare: OChainId.flare,
  gnosis: OChainId.gnosis,
  linea: OChainId.linea,
  optimism: OChainId.optimism,
  optimism_testnet: OChainId.optimismTestnet,
  polygon: OChainId.polygon,
  polygon_zkevm: OChainId.polygonZkEVM,
  polygon_mumbai: OChainId.mumbai,
  rollux: OChainId.rollux,
  scroll: OChainId.scroll,
  syscoin: OChainId.syscoin,
  zksync_era: OChainId.zkSync,
  neura_devnet: OChainId.neuraDevnet,
  neura_testnet_v1: OChainId.neuraTestnet,
  incentiv_devnet: OChainId.incentivDevnet,

  // todo: add the rest of the chains
  stellar: OChainId.unknown,
  bas_metaapes: OChainId.unknown,
  bas_metaapes_testnet: OChainId.unknown,
};

/**
 * @param chainName - Chain name.
 * @return Chain ID by chain name or undefined if chain name is not found.
 * @example
 * getChainIdByName('eth') // 1
 */
export function getChainIdByName(chainName?: string): TChainId | undefined {
  return chainName
    ? chainNameToIdMap[chainName as IApiBlockchainName]
    : undefined;
}
