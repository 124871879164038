import { useState } from 'react';
import { useGetLatestBlocksQuery } from 'api/getLatestBlocks';
import { IApiBlockchainName } from 'api/types';
import { DEFAULT_CHAIN } from 'common/const';

const SINGLE_CHAIN_BLOCKS_COUNT = 10;
const MULTI_CHAIN_BLOCKS_COUNT = 1;

export const useLatestBlocks = (
  chain: IApiBlockchainName | undefined = DEFAULT_CHAIN,
) => {
  const [blockchain, setBlockchainName] = useState(chain);

  const { data, error, isLoading, isFetching, refetch } =
    useGetLatestBlocksQuery({
      blockchainNames: blockchain,
      size: blockchain ? SINGLE_CHAIN_BLOCKS_COUNT : MULTI_CHAIN_BLOCKS_COUNT,
    });

  return {
    data,
    loading: isLoading || isFetching,
    error,
    blockchainNames: blockchain,
    setBlockchainNames: setBlockchainName,
    refetch,
  };
};
