import { useParams } from 'react-router';
import { useGetBlockDetailsQuery } from 'api/getBlockDetails';
import { IApiBlockchainName } from 'api/types';
import { DEFAULT_CHAIN } from 'common/const';
import { t } from 'common/utils/intl';
import { ErrorMessage } from 'components/ErrorMessage';
import { useWidgetRouteChange } from '../widget';
import { BlockUI } from './components/BlockUI/BlockUI';

export const Block = () => {
  const { blockchain = DEFAULT_CHAIN, blockHeight } = useParams<{
    blockchain: IApiBlockchainName;
    blockHeight: string;
  }>();

  const { data, error, refetch, isLoading, isFetching } =
    useGetBlockDetailsQuery({
      blockchain: blockchain as IApiBlockchainName,
      blockNumber: +(blockHeight as string),
    });

  useWidgetRouteChange('block', blockHeight, blockchain);

  const renderedError = () => {
    return (
      <ErrorMessage text={t('errorMessages.requestError')} onClick={refetch} />
    );
  };

  return (
    <BlockUI
      isLoading={isLoading || isFetching}
      error={error && renderedError()}
      blockchain={data?.blockchain}
      ethBlock={data?.ethBlock}
    />
  );
};
