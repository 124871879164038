import { IApiBlockchainName } from 'api/types';
import { CUSTOM_CHAINS } from './env';

export const ANKRSCAN_CHAINS: IApiBlockchainName[] = [
  'arbitrum',
  'avalanche',
  'bsc',
  'eth',
  'fantom',
  'polygon',
  'syscoin',
  'optimism',
  'rollux',
  'polygon_zkevm',
];

export const CHAIN_NAME: Record<IApiBlockchainName, string> = {
  arbitrum: 'chainName.eth',
  avalanche: 'chainName.avalanche',
  bsc: 'chainName.bsc',
  eth: 'chainName.eth',
  fantom: 'chainName.fantom',
  polygon: 'chainName.polygon',
  syscoin: 'chainName.syscoin',
  optimism: 'chainName.optimism',
  rollux: 'chainName.rollux',
  polygon_zkevm: 'chainName.polygon_zkevm',
  bas_metaapes: 'chainName.bas_metaapes',
  bas_metaapes_testnet: 'chainName.bas_metaapes_testnet',
  avalanche_fuji: 'chainName.avalanche_fuji',
  eth_goerli: 'chainName.eth_goerli',
  flare: 'chainName.flare',
  optimism_testnet: 'chainName.optimism_testnet',
  polygon_mumbai: 'chainName.polygon_mumbai',
  gnosis: 'chainName.gnosis',
  base: 'chainName.base',
  linea: 'chainName.linea',
  scroll: 'chainName.scroll',
  stellar: 'chainName.stellar',
  zksync_era: 'chainName.zksync_era',
  neura_devnet: 'chainName.neura_devnet',
  neura_testnet_v1: 'chainName.neura_testnet',
  incentiv_devnet: 'chainName.incentiv_devnet',
};

export const SUPPORTED_CHAINS = [...ANKRSCAN_CHAINS, ...(CUSTOM_CHAINS ?? [])];

export const DEFAULT_CHAIN = CUSTOM_CHAINS ? CUSTOM_CHAINS[0] : undefined;
