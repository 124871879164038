import { makeStyles } from '@material-ui/core';

export const useIncentivFooterStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(2, 0),
    color: theme.palette.text.secondary,

    [theme.breakpoints.up('md')]: {
      padding: theme.spacing(2.5, 0),
      fontSize: theme.typography.pxToRem(16),
    },
  },

  container: {
    display: 'grid',
    alignItems: 'center',
    justifyContent: 'center',
    gap: theme.spacing(1.5, 2),

    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'repeat(3, auto)',
      justifyContent: 'space-between',
    },

    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: '1fr auto 1fr',
      paddingLeft: theme.spacing(5),
      paddingRight: theme.spacing(5),
    },
  },

  link: {
    gap: theme.spacing(0.5),
    color: theme.palette.primary.main,
    transition: theme.transitions.create('color'),
    fontSize: 'inherit',
  },

  label: {
    color: '#82899A',
  },

  linkIcon: {
    width: 20,
    height: 20,
  },

  social: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.up('md')]: {
      justifyContent: 'flex-end',
    },
  },

  socialLink: {
    height: 36,
    width: 36,
    fontSize: 24,
    filter: 'grayscale(1)',
    transition: theme.transitions.create('filter'),

    '&:hover': {
      filter: 'grayscale(0)',
    },
  },

  socialIcon: {
    width: '1em',
    height: '1em',
    transition: theme.transitions.create('opacity'),
    opacity: 0.8,

    '$socialLink:hover &': {
      opacity: 1,
    },
  },

  socialIconDiscord: {
    opacity: 0.6,
  },

  socialIconTwitter: {
    opacity: 0.3,
  },
}));
