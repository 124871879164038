import { featureConfig } from 'common/featureConfig';
import { Address } from 'common/types';
import * as process from 'process';
import { SOURCIFY_API_URL } from '../common/const';

const baseUrl = SOURCIFY_API_URL ?? 'https://repo.sourcify.dev';

export type AbiInputType = 'uint256' | 'address' | 'bool';

export interface IAbiInput {
  name: string;
  type: AbiInputType;
}

type StateMutability = 'pure' | 'view' | 'nonpayable' | 'payable';

type AbiItemType = 'error' | 'function' | 'event' | 'constructor' | 'fallback';

const OContractRequestMode = {
  full: 'full_match',
  partial: 'partial_match',
} as const;

type TContractRequestMode =
  typeof OContractRequestMode[keyof typeof OContractRequestMode];

export interface IAbiItem {
  inputs: IAbiInput[];
  outputs: IAbiInput[];
  name: string;
  type: AbiItemType;
  stateMutability: StateMutability;
}

interface IContractData {
  output: {
    abi: IAbiItem[];
  };
}

/**
 * Sends a request to the Sourcify API to get the contract ABI.
 *
 * @async
 * @return Contract ABI
 */
export async function getContractAbi(
  chainId: number,
  address: Address,
): Promise<IAbiItem[]> {
  let response: Response;

  try {
    const url = getUrl(chainId, address, OContractRequestMode.full);
    const result = await fetch(url);
    if (!result.ok) {
      throw new Error('Failed to get contract ABI.');
    }
    response = result;
  } catch (error) {
    const url = getUrl(chainId, address, OContractRequestMode.partial);
    response = await fetch(url);
  }

  const result: IContractData = await response.json();

  return result.output.abi;
}

/**
 * Returns the URL for the contract ABI request.
 *
 * @param chainId Chain ID
 * @param address Contract address
 * @param mode Request mode (sourcify specific)
 * @return URL
 */
function getUrl(
  chainId: number,
  address: Address,
  mode: TContractRequestMode,
): string {
  return `${baseUrl}/contracts/${mode}/${chainId}/${address}/metadata.json`;
}
