import React, { ReactNode, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { uid } from 'react-uid';
import { IApiBlockchainName } from 'api/types';
import BigNumber from 'bignumber.js';
import classNames from 'classnames';
import { IBlockMapped } from 'common/types';
import { getTimePassed } from 'common/utils/getTimePassed';
import { BlockchainIcon } from 'components/BlockchainIcon';
import { NothingFound } from 'components/NothingFound';
import { SupportedBlockchains } from 'components/SupportedBlockchains';
import {
  TableBodyCell,
  TableCellAddress,
  TableRow,
} from 'components/TableComponents';
import { TableView } from 'components/TableView/TableView';
import { IS_EMBEDDED } from '../../common/const';
import { BlockRoutesConfig, TransactionsRouteConfig } from '../router/const';
import { BlocksSkeleton } from './BlocksSkeleton';
import { useBlocksStyles } from './BlocksStyles';

const blocksCaptions = [
  { key: 'height', label: 'Height' },
  { key: 'lastSeen', label: 'Last Seen' },
  { key: 'transactionsCount', label: 'Tx count' },
  { key: 'blockHash', label: 'Block hash' },
  { key: 'gasLimit', label: 'Gas limit' },
  { key: 'gasUsed', label: 'Gas used' },
  {
    key: 'miner',
    label: IS_EMBEDDED ? 'Validator address' : 'Miner address',
  },
];

const customCell = '130px 120px 90px 1fr 1fr 115px 1fr';

interface IBlocksUiProps {
  isBlocksFilterAvailable: boolean;
  items?: IBlockMapped[];
  isLoading?: boolean;
  error: ReactNode;
  blockchainNames?: IApiBlockchainName;
  setBlockchainNames?: (b: IApiBlockchainName | undefined) => void;
  className?: string;
}

export const BlocksUi = ({
  isBlocksFilterAvailable,
  setBlockchainNames,
  blockchainNames,
  items,
  isLoading,
  error,
  className,
}: IBlocksUiProps) => {
  const classes = useBlocksStyles();

  const renderedRows = useMemo(
    () =>
      items?.map(
        (
          {
            blockHeight,
            blockchain,
            blockchainLogo,
            transactionsCount,
            timestamp,
            blockHash,
            gasLimit,
            gasUsed,
            miner,
          },
          i,
        ) => {
          return (
            <TableRow key={uid(blockHeight, i)}>
              <TableBodyCell
                className={classes.tableCell}
                label={blocksCaptions[0].label}
              >
                <div className={classes.blockCell}>
                  <span className={classes.blockWrap}>
                    <Link
                      to={BlockRoutesConfig.blockDetails.generatePath(
                        blockchain,
                        blockHeight,
                      )}
                    >
                      {blockHeight}
                    </Link>
                  </span>

                  <BlockchainIcon
                    className={classes.icon}
                    type={blockchain}
                    title={blockchain}
                    logo={blockchainLogo}
                  />
                </div>
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={blocksCaptions[1].label}
              >
                {getTimePassed(timestamp)}
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={blocksCaptions[2].label}
              >
                {transactionsCount === 0 ? (
                  0
                ) : (
                  <Link
                    to={TransactionsRouteConfig.transactionsByBlock.generatePath(
                      blockchain,
                      blockHeight,
                    )}
                  >
                    {transactionsCount}
                  </Link>
                )}
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={blocksCaptions[3].label}
                tooltipText={blockHash}
              >
                <Link
                  to={BlockRoutesConfig.blockDetails.generatePath(
                    blockchain,
                    blockHeight,
                  )}
                >
                  {blockHash}
                </Link>
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={blocksCaptions[4].label}
              >
                {gasLimit ? new BigNumber(gasLimit).toFormat() : 0}
              </TableBodyCell>

              <TableBodyCell
                className={classes.tableCell}
                label={blocksCaptions[5].label}
              >
                {gasUsed ? new BigNumber(gasUsed).toFormat() : 0}
              </TableBodyCell>

              {miner ? (
                <TableCellAddress
                  label={blocksCaptions[6].label}
                  address={miner}
                  addressName={miner}
                  isContract={false}
                  plainText={false}
                />
              ) : (
                <TableBodyCell
                  className={classes.tableCell}
                  label={blocksCaptions[6].label}
                >
                  {'—'}
                </TableBodyCell>
              )}
            </TableRow>
          );
        },
      ),
    [classes, items],
  );

  const renderedContent = (
    <>
      <TableView
        tableGridTemplateColumns={customCell}
        transfersCaptions={blocksCaptions}
        className={classNames(classes.table, className)}
      >
        {isLoading ? (
          <BlocksSkeleton blocksCaptions={blocksCaptions} classes={classes} />
        ) : (
          renderedRows
        )}
      </TableView>
    </>
  );

  return (
    <>
      {isBlocksFilterAvailable && setBlockchainNames && (
        <SupportedBlockchains
          selected={blockchainNames}
          setSelected={setBlockchainNames}
        />
      )}
      {error}
      {(items?.length || isLoading) && renderedContent}
      {items?.length === 0 ? <NothingFound /> : null}
    </>
  );
};
