import React from 'react';
import { Box, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { useNeuraFilterHeaderStyles } from './useNeuraFilterHeaderStyles';

export function NeuraFilterHeader() {
  const classes = useNeuraFilterHeaderStyles();
  return (
    <Box textAlign="center" mb={3.5}>
      <Typography className={classes.title} variant="h1">
        {t('neura-filter-header.title')}
      </Typography>
    </Box>
  );
}
