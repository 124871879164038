import React, { FC } from 'react';
import { Link } from 'react-router-dom';
import { IApiBlockchainName } from 'api/types';
import { TransactionsRouteConfig } from 'modules/router/const';
import { useSuccessTxStyles } from './TxTitleStyles';

interface ITxTitleProps {
  blockchain: IApiBlockchainName;
  transactionHash: string;
  success: boolean;
}

export const TxTitle: FC<ITxTitleProps> = ({
  blockchain,
  transactionHash,
  success,
}) => {
  const classes = useSuccessTxStyles();
  const {
    transactionDetails: { generatePath },
  } = TransactionsRouteConfig;

  return (
    <div className={classes.title}>
      {!success && <span className={classes.noSuccess} />}
      <Link
        to={generatePath(blockchain, transactionHash)}
        className={classes.link}
      >
        {transactionHash}
      </Link>
    </div>
  );
};
