import { ButtonBase, Container, Typography } from '@material-ui/core';
import { ExternalLinkIcon } from 'uiKit/Icons';
import { useIncentivFooterStyles } from './useIncentivFooterStyles';

const VERIFIER_LINK = 'https://sourcify.neura-dev.ankr.network/#/verifier';

const currentYear = new Date().getFullYear();

export function IncentivFooter(): JSX.Element {
  const classes = useIncentivFooterStyles();

  return (
    <footer className={classes.root}>
      <Container className={classes.container} maxWidth={false}>
        <Typography color="primary">{`©${currentYear}. Incentiv All rights reserved`}</Typography>

        <ButtonBase
          component="a"
          className={classes.link}
          href={VERIFIER_LINK}
          rel="noreferrer"
          target="_blank"
          disableRipple
        >
          {'Contract Verifier'}

          <ExternalLinkIcon className={classes.linkIcon} />
        </ButtonBase>
      </Container>
    </footer>
  );
}
