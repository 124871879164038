import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';
import { PALETTE } from '../../common/themes/mainTheme';

export const useBlockchainStatsStyles = makeStyles<Theme>(theme => ({
  title: {
    marginBottom: theme.spacing(4),
    color: theme.palette.primary.main,
  },
  paper: {
    paddingTop: theme.spacing(2),
    marginBottom: theme.spacing(6),
  },
  table: {
    borderRadius: 20,
    border: 'none',
  },
}));
