import {
  IS_EMBEDDED,
  REQUEST_ERROR_MESSAGE,
  SUPPORTED_CHAINS,
  CUSTOM_CHAINS,
} from 'common/const';
import { explorerRpc } from './explorerRpc';
import { IApiBlock } from './types';
import { buildRpcRequest } from './utils/buildRpcRequest';

interface IBlocksReply {
  result?: { blocks: IApiBlock[] };
  error?: { code: number; message: string };
}

type BlocksQueryV2 = {
  items?: IApiBlock[];
};

interface IRequestParams {
  blockNumber: number;
}

export const { useGetBlocksByHeightQuery } = explorerRpc.injectEndpoints({
  endpoints: build => ({
    getBlocksByHeight: build.query<BlocksQueryV2, IRequestParams>({
      query: ({ blockNumber }) =>
        buildRpcRequest({
          method: 'explorer_getBlocks',
          params: {
            blockNumber,
            blockchain: IS_EMBEDDED ? CUSTOM_CHAINS : undefined,
          },
        }),

      transformResponse: (data: IBlocksReply) => {
        if (data.error || !data.result?.blocks) {
          throw data.error || REQUEST_ERROR_MESSAGE;
        }

        return {
          items: data.result.blocks.map(b => {
            const isBlockchainSupported = SUPPORTED_CHAINS.includes(
              b?.blockchain,
            );
            return {
              ...b,
              ...b.details?.ethBlock,
              blockchain: b?.blockchain,
              blockchainLogo: isBlockchainSupported ? '' : b?.blockchainLogo,
            };
          }),
        };
      },
    }),
  }),

  overrideExisting: false,
});
