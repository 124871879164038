import { Box, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { CopyAddress } from 'components/CopyAddress';
import { SupportedBlockchains } from 'components/SupportedBlockchains';
import { Container } from 'uiKit/Container';
import { IS_SINGLE_CHAIN } from '../../common/const';
import { PageTop } from '../../components/PageTop';
import { Layout } from '../Layout';
import { useWidgetRouteChange } from '../widget';
import { AddressAssets } from './components/AddressAssets';
import { AddressTransactions } from './components/AddressTransactions';
import { useAddress } from './hooks/useAddress';
import { useAddressStyles } from './AddressStyles';

const isChainTabsVisible = !IS_SINGLE_CHAIN;

export const Address = () => {
  const classes = useAddressStyles();

  const {
    address,
    selectedBn,
    setSelectedBn,
    tabTx,
    setTxsTab,
    tabBalances,
    setTabAssets,
  } = useAddress();

  useWidgetRouteChange('address', address, selectedBn);

  return (
    <Layout>
      <section className={classes.root}>
        <Container>
          <PageTop>
            <div className={classes.header}>
              <Typography className={classes.title} variant="h2">
                {t('address.title')}
              </Typography>

              <Box overflow="hidden">
                <CopyAddress
                  className={classes.addressText}
                  address={address}
                />
              </Box>
            </div>
          </PageTop>

          {isChainTabsVisible && (
            <SupportedBlockchains
              selected={selectedBn}
              setSelected={setSelectedBn}
            />
          )}

          <AddressAssets
            tab={tabBalances}
            onTabChange={setTabAssets}
            blockchain={selectedBn}
          />

          <AddressTransactions
            tab={tabTx}
            onTabChange={setTxsTab}
            blockchain={selectedBn}
          />
        </Container>
      </section>
    </Layout>
  );
};
