import { Theme } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

export const useLatestStyles = makeStyles<Theme>(theme => ({
  root: {
    padding: theme.spacing(2.125, 0, 10),
    overflow: 'hidden',
    [theme.breakpoints.up('sm')]: {
      padding: theme.spacing(2.125, 0, 8),
    },
  },
  paper: {
    padding: theme.spacing(2.5, 0),
    borderRadius: 12,
  },
  title: {
    fontWeight: 600,
    fontSize: 18,
    padding: theme.spacing(0, 2.5, 2),
    borderBottom: '1px solid #E7E9ED',
  },
  list: {
    padding: 0,
    margin: theme.spacing(0, 0, 2),
  },
  item: {
    color: theme.palette.text.primary,
    display: 'grid',
    borderBottom: '1px solid #e7eaf3',
    alignItems: 'center',
    padding: theme.spacing(2, 2.5),
    lineHeight: 1.2,
    transition: 'background-color .3s',
    [theme.breakpoints.up('sm')]: {
      height: 68,
    },
    '&:hover': {
      backgroundColor: theme.palette.background.default,
    },
  },
  itemSkeleton: {
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(0.5, 3),
  },

  itemBlock: {
    gridTemplateAreas: `
      'icon block-height txns'
      'icon time-passed txns'
    `,
    gridTemplateColumns: 'auto 1fr auto',
    gap: theme.spacing(0.5, 2),
  },
  itemTransaction: {
    gridTemplateAreas: `
      'icon block-height'
      'icon miner-from'
      'icon miner-to'
      'icon time-passed'
      'icon amount'
    `,
    gridTemplateColumns: 'auto 1fr',
    gap: theme.spacing(1, 2),
    [theme.breakpoints.up('sm')]: {
      gridTemplateAreas: `
      'icon block-height miner-from amount'
      'icon time-passed miner-to amount'
    `,
      gridTemplateColumns: 'min-content 120px auto 25%',
      gap: theme.spacing(0.5, 4),
    },
    [theme.breakpoints.up('md')]: {
      gridTemplateColumns: 'min-content 20% auto 25%',
    },
    [theme.breakpoints.up('lg')]: {
      gridTemplateColumns: 'min-content 100px auto 25%',
    },
    [theme.breakpoints.up('xl')]: {
      gridTemplateColumns: 'min-content 130px auto 25%',
    },
  },
  icon: {
    gridArea: 'icon',
    fontSize: 36,
  },
  iconTransaction: {
    [theme.breakpoints.up('sm')]: {
      /* for fixing gap */
      marginRight: -20,
    },
  },
  timePassed: {
    whiteSpace: 'nowrap',
    gridArea: 'time-passed',
    alignSelf: 'start',
    fontSize: 14,
    textOverflow: 'ellipsis',
    overflow: 'hidden',
    fontWeight: 500,
    color: '#69738C',
  },
  txns: {
    gridArea: 'txns',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  blockHeight: {
    gridArea: 'block-height',
    alignSelf: 'end',
    justifySelf: 'start',
    color: theme.palette.primary.main,
    fontWeight: 500,
  },
  miner: {
    gridArea: 'miner',
    display: 'flex',
    alignItems: 'center',
    color: 'inherit',
    textDecoration: 'none',
  },
  minerLeft: {
    marginRight: theme.spacing(0.5),
  },
  minerFrom: {
    gridArea: 'miner-from',
    alignSelf: 'end',
  },
  minerTo: {
    gridArea: 'miner-to',
    alignSelf: 'start',
  },
  truncatedStringWrapper: {
    display: 'grid',
    maxWidth: '50%',
    [theme.breakpoints.up('sm')]: {
      maxWidth: '100%',
    },
  },
  truncatedString: {
    overflow: 'hidden',
    whiteSpace: 'nowrap',
    textOverflow: 'ellipsis',
  },
  amount: {
    gridArea: 'amount',
    fontSize: 14,
    whiteSpace: 'nowrap',
    display: 'grid',
    gridTemplateColumns: 'max-content auto',
    [theme.breakpoints.up('sm')]: {
      gridTemplateColumns: '1fr auto',
      textAlign: 'right',
    },
  },
  amountNumber: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    marginRight: theme.spacing(0.5),
  },
  linkWrap: {
    padding: theme.spacing(0, 2.5),
  },
  link: {
    fontWeight: 700,
    fontSize: 12,
    border: `2px solid #F2F5FA`,
  },
}));
