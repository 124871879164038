import React from 'react';
import { Grid, Paper, Typography } from '@material-ui/core';
import { t } from 'common/utils/intl';
import { Container } from 'uiKit/Container';
import { NavLink } from 'uiKit/NavLink';
import { IS_EMBEDDED } from '../../../../common/const';
import { PATH } from '../../../router/const';
import { LatestBlocks } from './LatestBlocks';
import { LatestTransactions } from './LatestTransactions';
import { useLatestStyles } from './LatestStyles';

export const Latest = () => {
  const classes = useLatestStyles();
  return (
    <section className={classes.root}>
      <Container>
        <Grid container spacing={5}>
          <Grid item xs={12} lg>
            <Paper className={classes.paper}>
              <Typography variant="h2" className={classes.title}>
                {t('latest.blocks.title')}
              </Typography>

              <LatestBlocks />

              <div className={classes.linkWrap}>
                <NavLink
                  className={classes.link}
                  href={PATH.BLOCKS}
                  variant="outlined"
                  color="default"
                  fullWidth
                >
                  {t('latest.blocks.btnExpand')}
                </NavLink>
              </div>
            </Paper>
          </Grid>

          {!IS_EMBEDDED && (
            <Grid item xs={12} lg>
              <Paper className={classes.paper}>
                <Typography variant="h2" className={classes.title}>
                  {t('latest.transactions.title')}
                </Typography>
                <LatestTransactions />
                <div className={classes.linkWrap}>
                  <NavLink
                    className={classes.link}
                    href={`${PATH.TRANSACTIONS}`}
                    variant="outlined"
                    color="default"
                    fullWidth
                  >
                    {t('latest.transactions.btnExpand')}
                  </NavLink>
                </div>
              </Paper>
            </Grid>
          )}
        </Grid>
      </Container>
    </section>
  );
};
